import * as React from "react";
import {useContext, useState} from "react";
import {Button, Container, SpaceBetween, Spinner} from "@amzn/awsui-components-react/polaris";
import {queryDtpByTwoPartKey} from "./RuleFileUtils";
import {buildMetric} from "../../../metric/metric";
import {Header, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import {ShadowReportMetadataContext} from "../../../contexts/ShadowReportMetadataContext";
import {ShadowReportMetadataEntries} from "../../../storage/ShadowReportMetadataSheet";
import tacomaRuleFileTypes from "./tacomaRuleFiles.json";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";
import {ShadowReportType} from "../../../hook/UseShadowReportInfo";


enum RuleFileDiffStatus {
    NONE,
    SUCCEEDED,
    FAILED,
    GETTING_TRANSACTION_IDENTIFIER,
    GETTING_DECISIONS_AND_RULE_FILE_DIFFS
}

export interface DtpRequest {
    groupId: string;
    groupIdDomain: string;
    stage: string;
    realm: string;
    services: string;
}

export function RuleFileDiffButton({shadowReportName, shadowReportType}) {
    const {shadowReportMetadata} = useContext(ShadowReportMetadataContext);
    const [status, setStatus] = useState(RuleFileDiffStatus.NONE);
    const [errorMessage, setErrorMessage] = useState("");
    const [dtpRequest, setDtpRequest] = useState(undefined);
    const [changedRuleFiles, setChangedRuleFiles] = useState(undefined);
    const [ruleDecisions, setRuleDecisions] = useState(undefined);
    const [uniqueRuleFiles, setUniqueRuleFiles] = useState(undefined);

    const isTerminalStatus = (status: RuleFileDiffStatus): boolean => {
        return [RuleFileDiffStatus.NONE, RuleFileDiffStatus.SUCCEEDED, RuleFileDiffStatus.FAILED].includes(status);
    }
    const buildDtpRequest = async () => {
        setStatus(RuleFileDiffStatus.GETTING_TRANSACTION_IDENTIFIER);
        let dtpRequest: DtpRequest;
        const dtpQueryStage = "GAMMA";

        await Excel.run(async (context) => {
            const selectedRange = context.workbook.getSelectedRange();
            const sheet = selectedRange.worksheet;
            selectedRange.load("rowIndex");
            await context.sync();
            if (selectedRange.rowIndex == 0) {
                setErrorMessage("Please select a row.");
                console.log("Please select a row.");
                setStatus(RuleFileDiffStatus.FAILED);
                return;
            }

            console.log("Finding request inputs depending on Shadow Report Type...");
            const usedRange = sheet.getUsedRange();
            usedRange.load("columnCount");
            await context.sync();
            console.log(`usedRange.columnCount: ${usedRange.columnCount}`);
            const headerRow = sheet.getRangeByIndexes(0, 0, 1, Math.min(100, usedRange.columnCount));
            headerRow.load("values");
            await context.sync();
            const headerValues = headerRow.values[0];
            console.log(`headerValues: ${headerValues}`);

            let groupIdCell, groupIdDomainCell;
            let compoundIdCell;
            let dtpQueryRealm: string = shadowReportMetadata[ShadowReportMetadataEntries.ShadowReportRegion];
            let dtpQueryServices: string, groupId: string, groupIdDomain: string;
            switch (shadowReportType) {
                case "TAXMAN":
                    // Taxman Shadow Report queries Taxman in DTP
                    // Query TACOS for now since Taxman is not onboarded yet.
                    // No result is expected to be returned, but it's better than having exception when querying Taxman.
                    // dtpQueryService = 'TAXMAN';
                    console.log("Getting request input for Taxman Shadow Report");
                    dtpQueryServices = "TACOS";
                    // Get 2PK from groupId and groupIdDomain columns
                    groupIdCell = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("groupId"));
                    groupIdDomainCell = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("groupIdDomain"));
                    groupIdCell.load("values");
                    groupIdDomainCell.load("values");
                    await context.sync();
                    groupId = String(groupIdCell.values.flat()[0]);
                    groupIdDomain = String(groupIdDomainCell.values.flat()[0]);
                    break;

                case "TRE":
                    console.log("Getting request input for TRE Shadow Report");
                    // TRE Shadow Report queries TACOS in DTP
                    dtpQueryServices = "TRE,TACOS";
                    groupIdCell = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("groupId"));
                    groupIdDomainCell = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("groupIdDomain"));
                    groupIdCell.load("values");
                    groupIdDomainCell.load("values");
                    await context.sync();
                    groupId = String(groupIdCell.values.flat()[0]);
                    groupIdDomain = String(groupIdDomainCell.values.flat()[0]);
                    break;

                case ShadowReportType.TRE_V2:
                    console.log("Getting request input for TRE Shadow Report");
                    // TRE Shadow Report queries TACOS in DTP
                    dtpQueryServices = "TRE,TACOS";
                    compoundIdCell = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("TAR Identifier"));
                    compoundIdCell.load("values");
                    await context.sync();
                    const splitId = String(compoundIdCell.values.flat()[0]).split(".");
                    groupId = splitId[0];
                    groupIdDomain = splitId[1];
                    break;

                default:
                    setErrorMessage("Unknown Shadow Report Type");
                    throw new Error("Unknown Shadow Report Type");
            }

            dtpRequest = {
                groupId: groupId,
                groupIdDomain: groupIdDomain,
                stage: dtpQueryStage,
                realm: dtpQueryRealm,
                services: dtpQueryServices,
            }
        });
        return dtpRequest;
    }

    const queryDecisionsAndRuleFileDiffs = async (dtpRequest: DtpRequest) => {
        setStatus(RuleFileDiffStatus.GETTING_DECISIONS_AND_RULE_FILE_DIFFS);
        return queryDtpByTwoPartKey(dtpRequest);
    }

    const processResponse = async (dtpResponse) => {
        setChangedRuleFiles(dtpResponse.ruleChangeResponses);
        setRuleDecisions(dtpResponse.ruleDecisions);
        const usedRuleFiles = dtpResponse.ruleDecisions.flatMap((ruleDecision) =>
            ruleDecision.ruleIds.map((ruleId) => {
                return {
                    ruleFileNamePrefix: ruleId.ruleFileNamePrefix,
                    ruleFileVersion: ruleId.ruleFileVersion,
                };
            })
        );
        const uniqueUsedRuleFiles = getUniqueRuleFiles(usedRuleFiles)
        setUniqueRuleFiles(uniqueUsedRuleFiles);
    }

    function getUniqueRuleFiles(ruleFiles) {
        return ruleFiles.filter((value, index, array) =>
            index == array.findIndex(item =>
                item.ruleFileNamePrefix == value.ruleFileNamePrefix &&
                item.ruleFileVersion.codeVersion == value.ruleFileVersion.codeVersion &&
                item.ruleFileVersion.majorVersion == value.ruleFileVersion.majorVersion &&
                item.ruleFileVersion.minorVersion == value.ruleFileVersion.minorVersion
            ));
    }

    const renderRuleFileVersion = (ruleFileVersion) => {
        return `${ruleFileVersion.codeVersion}.${ruleFileVersion.majorVersion}.${ruleFileVersion.minorVersion}`;
    }


    const renderRuleFileTacomaDiffLink = (gammaRuleFile, prodRuleFile) => {
        return `https://console.tax.amazon.dev/tacoma/diff/${gammaRuleFile.ruleFileType}/${gammaRuleFile.region}/${gammaRuleFile.ruleFileLabel}/${gammaRuleFile.ruleFileVersion.codeVersion}/${gammaRuleFile.ruleFileVersion.majorVersion}/${prodRuleFile.ruleFileVersion.codeVersion}/${prodRuleFile.ruleFileVersion.majorVersion}/?mode=testing`;
    };

    const renderRuleFileDiffLink = (gammaRuleFile, prodRuleFile) => {
        return `https://rule-file-mgmt.amazon.com/RuleFiles/diffWithPrevious?utf8=%E2%9C%93&version_to_diff=${prodRuleFile.ruleFileVersion.majorVersion}.${prodRuleFile.ruleFileVersion.minorVersion}&rule_file_type=${gammaRuleFile.ruleFileType}&region=${gammaRuleFile.region}&country_code=&label=${gammaRuleFile.ruleFileLabel}&code_version=${gammaRuleFile.ruleFileVersion.codeVersion}&major_version=${gammaRuleFile.ruleFileVersion.majorVersion}&minor_version=${gammaRuleFile.ruleFileVersion.minorVersion}&diff_type=content&show_code_version=true&show_all_lines=false&commit=Diff&domain=TaxBusiness`;
    }

    const click = async () => {
        const metric = await buildMetric("RuleFileDiff", shadowReportMetadata);

        metric.addCount("RuleFileDiffInvoked", 1);
        metric.addProperty("ShadowReportName", shadowReportName);
        metric.addProperty("ShadowReportType", shadowReportType);

        try {
            setErrorMessage(undefined);
            setDtpRequest(undefined);
            setChangedRuleFiles(undefined);
            setRuleDecisions(undefined);
            setUniqueRuleFiles(undefined);
            let tempDtpRequest = await buildDtpRequest();
            if (tempDtpRequest !== undefined) {
                setDtpRequest(tempDtpRequest);
                metric.addProperty("Realm", tempDtpRequest.realm);
                metric.addProperty("GroupIdDomain", tempDtpRequest.groupIdDomain);

                const dtpResponse = await queryDecisionsAndRuleFileDiffs(tempDtpRequest);
                console.log(`DTP Response: ${JSON.stringify(dtpResponse)}`)
                await processResponse(dtpResponse);
                setStatus(RuleFileDiffStatus.SUCCEEDED);
            }
        } catch (err) {
            setErrorMessage(`Error: ${err}`);
            console.error(`Error: ${err}`);
            setStatus(RuleFileDiffStatus.FAILED);
        } finally {
            validateAndPublishMetric(metric);
        }
    };

    let description = "";
    let uiStatusIcon: StatusIndicatorProps.Type = "in-progress";
    switch (status) {
        case RuleFileDiffStatus.NONE:
            break;
        case RuleFileDiffStatus.SUCCEEDED:
            uiStatusIcon = "success";
            description = "Query succeeded."
            break;
        case RuleFileDiffStatus.FAILED:
            uiStatusIcon = "error";
            description = "Query failed."
            break;
        case RuleFileDiffStatus.GETTING_TRANSACTION_IDENTIFIER:
            description = "Getting transaction identifier...";
            break;
        case RuleFileDiffStatus.GETTING_DECISIONS_AND_RULE_FILE_DIFFS:
            description = "Getting rule file diffs...";
            break;
    }

    return (
        <Container header={
            <Header description="Select a row and click the button to get Rule File Diff information.">
                Rule File Diff Analysis
            </Header>}>
            <SpaceBetween direction="vertical" size={"s"}>
                <Button variant="primary" onClick={click}>{isTerminalStatus(status) ? "Rule File Diffs" :
                    <Spinner/>}</Button>
                {
                    status != RuleFileDiffStatus.NONE &&
                    <StatusIndicator type={uiStatusIcon}>
                        {description}
                    </StatusIndicator>
                }
                {errorMessage && (
                    <p className="awsui-util-status-negative"> {errorMessage} </p>
                )}
                {dtpRequest &&
                    <SpaceBetween direction="vertical" size={"s"}>
                        <strong>Query Input:</strong>
                        <p>
                            GroupId: <strong>{dtpRequest["groupId"]}</strong><br/>
                            GroupIdDomain: <strong>{dtpRequest["groupIdDomain"]}</strong><br/>
                            Realm: <strong>{dtpRequest["realm"]}</strong><br/>
                            Services: <strong>{dtpRequest["services"]}</strong><br/>
                        </p>
                    </SpaceBetween>
                }
                {changedRuleFiles &&
                    <SpaceBetween direction="vertical" size={"s"}>
                        {changedRuleFiles?.length > 0 ?
                            <><strong>Changed Rule Files:</strong>
                                <ol>
                                    {changedRuleFiles.map((ruleFile) =>
                                        <li key={ruleFile.gammaRuleFile.ruleFileNamePrefix}>
                                            <a href={renderRuleFileDiffLink(ruleFile.gammaRuleFile, ruleFile.prodRuleFile)}>
                                                {ruleFile.gammaRuleFile.ruleFileNamePrefix}
                                            </a> changed from
                                            [{renderRuleFileVersion(ruleFile.prodRuleFile.ruleFileVersion)}] to
                                            [{renderRuleFileVersion(ruleFile.gammaRuleFile.ruleFileVersion)}]
                                            {tacomaRuleFileTypes.find(t => ruleFile.gammaRuleFile.ruleFileType === t) ?
                                                <i>
                                                    {" "}
                                                    <a href={renderRuleFileTacomaDiffLink(ruleFile.gammaRuleFile, ruleFile.prodRuleFile)}>
                                                        (Tacoma Diff)
                                                    </a>
                                                </i>
                                                : undefined
                                            }
                                        </li>
                                    )}
                                </ol>
                            </>
                            : <strong>No rule file diff is detected.</strong>
                        }
                    </SpaceBetween>
                }
                {ruleDecisions && uniqueRuleFiles &&
                    <SpaceBetween direction="vertical" size={"s"}>
                        <strong>Rule File Decisions:</strong>
                        <p>
                            Number of rule decisions: <strong>{ruleDecisions.length}</strong><br/>
                            Number of unique rule files hit: <strong>{uniqueRuleFiles.length}</strong><br/>
                            <ol>
                                {uniqueRuleFiles.map((ruleFile) =>
                                    <li key={ruleFile.ruleFileNamePrefix}>
                                        {ruleFile.ruleFileNamePrefix} [{renderRuleFileVersion(ruleFile.ruleFileVersion)}]
                                    </li>
                                )}
                            </ol>
                        </p>
                    </SpaceBetween>
                }
            </SpaceBetween>
        </Container>
    );
}
