import * as React from "react";
import {useContext, useEffect} from "react";
import {publishAppLoadMetric} from "@amzn/tax-platform-console-metrics";
import Progress from "./Progress";
import {midwayFetch} from "../../auth/fetcher";
import {aliasStorageEntry} from "../../storage/StorageEntries";
import {ShadowReportMetadataForm} from "./ShadowReportMetadataForm/ShadowReportMetadataForm";
import {LandingPage} from "./LandingPage/LandingPage";
import {ShadowReportMetadataContext, ShadowReportMetadataEditingContext} from "../../contexts/ShadowReportMetadataContext";
import {Tabs} from "@amzn/awsui-components-react";
import {DownloadSnapshot} from "./SnapshotButton/DownloadSnapshot/DownloadSnapshot";
import {useShadowReportName} from "../../hook/UseShadowReportName";

/* global console, Excel, require  */


export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
}

export default function App(props: AppProps) {
    const {title, isOfficeInitialized} = props;
    const {shadowReportMetadataFormFieldsAssigned} = useContext(ShadowReportMetadataContext);
    const {shadowReportMetadataEditing} = useContext(ShadowReportMetadataEditingContext);
    const shadowReportName = useShadowReportName();

    useEffect(() => {
        const auth = async () => {
            console.log("auth")
            await midwayFetch("https://alpha.metrics.console.tax.amazon.dev");
            publishAppLoadMetric(await aliasStorageEntry.get() || "unknown");
        }

        auth().catch((error) => {
            console.log(`auth error: ${error}`)
        });
    }, [])


    if (!isOfficeInitialized) {
        return (
            <Progress
                title={title}
                logo={require("./../../../assets/logo-filled.png")}
                message="Please sideload your addin to see app body."
            />
        );
    }

    return (
        <Tabs tabs={[
            {
                label: "Main",
                id: "main",
                content:
                    shadowReportMetadataEditing || !shadowReportMetadataFormFieldsAssigned ?
                        <ShadowReportMetadataForm shadowReportName={shadowReportName}/> :
                        <LandingPage shadowReportName={shadowReportName}/>,
            },
            {
                label: "Download",
                id: "download",
                content: <DownloadSnapshot/>,
            },
        ]}
        />
    );
}
