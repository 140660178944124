import * as React from "react";
import {useContext} from "react";
import {Button, Container, Header, Link, SpaceBetween} from "@amzn/awsui-components-react";
import {Snapshot} from "../SnapshotButton/Snapshot";
import {ContextGenerationButton} from "../ContextGenerationButton/ContextGenerationButton";
import {ClusteringButton} from "../ClusteringButton/ClusteringButton";
import {RuleFileDiffButton} from "../RuleFileDiffButton/RuleFileDiffButton";
import {ShadowReportType, useShadowReportInfo} from "../../../hook/UseShadowReportInfo";
import {ShadowReportMetadataEditingContext} from "../../../contexts/ShadowReportMetadataContext";
import {RuleFileDiffButtonSamplePointer} from "../RuleFileDiffButton/RuleFileDiffButtonSamplePointer";

const FEEDBACK_LINK = "https://survey.fieldsense.whs.amazon.dev/survey/5da69cb6-28b7-4417-a4cb-fd0f5a1042a7";

interface LandingPageProps {
    shadowReportName: string
}

export function LandingPage({shadowReportName}: LandingPageProps) {
    const {shadowReportType, availableClusteringSourceTypes} = useShadowReportInfo();
    const {setShadowReportMetadataEditing} = useContext(ShadowReportMetadataEditingContext);

    const headerText = ShadowReportType.UNKNOWN === shadowReportType ?
        "Shadow Report Type Unknown" :
        `${shadowReportType} Shadow Report`;

    return (
        <Container header={
            <Header>
                {headerText}
            </Header>
        }
                   footer={
                       <Link href={FEEDBACK_LINK}>Feedback</Link>
                   }
        >
            <SpaceBetween direction="vertical" size={"s"}>
                <Snapshot shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
                <ClusteringButton shadowReportName={shadowReportName} shadowReportType={shadowReportType}
                                  availableClusteringSourceTypes={availableClusteringSourceTypes}/>
                <RuleFileDiffButtonSamplePointer shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
                <RuleFileDiffButton shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
                <ContextGenerationButton shadowReportName={shadowReportName}/>
                <Button onClick={() => setShadowReportMetadataEditing(true)}
                        iconName="edit">
                    Edit Metadata
                </Button>
            </SpaceBetween>
        </Container>
    );
}
