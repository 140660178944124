import { useContext, useEffect, useRef, useState } from "react";
import {
    CLUSTER_SOURCE_TYPES,
    ClusteringSourceType,
    DIFF_SUMMARY_COL_NAME,
    DIFF_SUMMARY_COL_NAME_WITH_SPACE,
    MISMATCHING_FIELDS_SHEET_COL_NAME,
    RULE_DIFF_DETAIL_COL_NAME,
    RULE_DIFF_SUMMARY_COL_NAME,
    ShadowReportType
} from "../../../hook/UseShadowReportInfo";
import { buildColumnAddressFromColumnIndex, convertExcelColumnToCSV, insertEmptyCoumnsToTheLeftOfTheSheet, loadColumn, loadHeader, loadSheet, loadSheetUsedRange, populateColumnHeader} from "./file.helper";
import {v4 as uuidv4} from "uuid";
import { getPresignedURLForClusteringFileUploading, getPresignedURLForClusteringResult, uploadCSVFileForClustring } from "./api.helper";
import { validateAndPublishMetric } from "@amzn/tax-platform-console-metrics";
import { buildMetric } from "../../../metric/metric";
import Papa from "papaparse";
import { IShadowReportMetadata } from "../../../storage/ShadowReportMetadataSheet";
import { ShadowReportMetadataContext } from "../../../contexts/ShadowReportMetadataContext";


const CLUSTERING_QUERY_INTERVAL_MS = 5_000;

const CLUSTERING_TIMEOUT_MS = 900_000; // 15 minutes

export enum ClusteringJobsState {
    NEVER_TRIGGERED,
    UPLOADING,
    CLUSTERING,
    POPULATING_EXCEL,
    TIMED_OUT,
    FAILED,
    SUCCEEDED
}

interface ClusteringButtonProps {
    shadowReportName: string,
    shadowReportType: ShadowReportType,
    selectedClusteringTypes: Array<ClusteringSourceType>
}

interface ClusteringJob {
    clusteringResultPreSignedURL: string,
    clusteringJobType: ClusteringSourceType,
    timeoutTimestamp: number,
    clusterColumnIndex: number,
    topicColumnIndex: number,
    clusteringResult?: string
}

const generatedClusteringTopicColumnHeader = (clusteringType: ClusteringSourceType) => {
    return `${clusteringType} Topic`;
}

const generatedClusterColumnHeader = (clusteringType: ClusteringSourceType) => {
    return `${clusteringType} Cluster`;
}

const getColumnNameToRunClusteringOn = (clusteringType: ClusteringSourceType, shadowReportType: ShadowReportType) => {
    switch (clusteringType) {
        case ClusteringSourceType.RULE_DIFF_DETAIL:
            return RULE_DIFF_DETAIL_COL_NAME
        case ClusteringSourceType.RULE_DIFF_SUMMARY:
            return RULE_DIFF_SUMMARY_COL_NAME
        case ClusteringSourceType.DIFF_SUMMARY:
            return getColumnNameToRunClusteringOnForDiffSummaryClusteringType(shadowReportType)
    }
}

const getColumnNameToRunClusteringOnForDiffSummaryClusteringType = (shadowReportType: ShadowReportType) => {
    switch (shadowReportType) {
        case ShadowReportType.TAXMAN:
            return MISMATCHING_FIELDS_SHEET_COL_NAME;
        case ShadowReportType.TRE:
            return DIFF_SUMMARY_COL_NAME;
        case ShadowReportType.TRE_V2:
            return DIFF_SUMMARY_COL_NAME_WITH_SPACE;
        case ShadowReportType.UNKNOWN:
            throw new Error("Unsupported shadow report type");
    }
}

const insertEmptyColumnsForClusteringResults = async (selectedClusteringTypes: Array<ClusteringSourceType>) => {
    const numberEmptyOfColumns = selectedClusteringTypes.length;

    await Excel.run(async context => {
        await insertEmptyCoumnsToTheLeftOfTheSheet(context, numberEmptyOfColumns * 2);
    })
}

const populateHeadersForClusteringResultColumns = async (selectedClusteringTypes: Array<ClusteringSourceType>) => {
    for (let columnIdx = 0; columnIdx < selectedClusteringTypes.length; columnIdx++) {
        await Excel.run(async context => {
            populateColumnHeader(context, columnIdx * 2, generatedClusterColumnHeader(selectedClusteringTypes[columnIdx]));
            populateColumnHeader(context, columnIdx * 2 + 1, generatedClusteringTopicColumnHeader(selectedClusteringTypes[columnIdx]));
        });
    }
}

const loadColumnToRunClusteringOn = async (clusteringType: ClusteringSourceType, shadowReportType: ShadowReportType) => {
    return Excel.run(async context => {
        const columnNameToRunClusteringOn = getColumnNameToRunClusteringOn(clusteringType, shadowReportType);
        const header = await loadHeader(context);

        const columnIndexToRunClusteringOn = header.indexOf(columnNameToRunClusteringOn);
        const column = await loadColumn(context, columnIndexToRunClusteringOn);

        if (ShadowReportType.TRE_V2 === shadowReportType && ClusteringSourceType.RULE_DIFF_SUMMARY !== clusteringType) {
            column[0][0] = DIFF_SUMMARY_COL_NAME;
        }

        return column;
    })
}

const uploadColumnsForClustering = async (selectedClustertingTypes: Array<ClusteringSourceType>, shadowReportType: ShadowReportType) => {
    const columns = await Promise.all(selectedClustertingTypes.map(type => loadColumnToRunClusteringOn(type, shadowReportType)));

    const filenames = await Promise.all(columns.map((column) => {
        const filename = `${uuidv4()}.csv`;
        return getPresignedURLForClusteringFileUploading(filename)
            .then(preSignedURL => {
                return uploadCSVFileForClustring(preSignedURL, convertExcelColumnToCSV(column));
            })
            .then(() => {
                return filename;
            })
    }))
    return filenames
}

const generateClusteringResultFilePreSignedURLs = async (clusteringInputFilenames: string[]) => {
    const clusteringResultPreSignedURLs = await Promise.all(clusteringInputFilenames.map(filename => getPresignedURLForClusteringResult(filename)));
    return clusteringResultPreSignedURLs
}

const generateClusteringJobsToQuery = (clusteringTypes: Array<ClusteringSourceType>, clusteringResultFilePreSignedURLs: string[]) => {
    return clusteringTypes.map((type, idx) => {
        return {
            clusteringResultPreSignedURL: clusteringResultFilePreSignedURLs[idx],
            clusteringJobType: type,
            timeoutTimestamp: CLUSTERING_TIMEOUT_MS + Date.now(),
            clusterColumnIndex: idx * 2,
            topicColumnIndex: idx * 2 + 1,
        }
    });
}

const queryClusteringResult = async (job: ClusteringJob) => {
    const resp = await fetch(job.clusteringResultPreSignedURL);
    if (resp.ok) {
        job.clusteringResult = await resp.text();
    } else if (resp.status !== 404) {
        Promise.reject(resp);
    }
    return job;
}

const haveAllClusteringJobFinsihedSuccessfully = (clusteringJobs: Array<ClusteringJob>) => {
    return clusteringJobs.every(job => job.clusteringResult);
}

const doesAnyClusteringJobTimeout = (clusteringJobs: Array<ClusteringJob>, shadowReportMetadata: IShadowReportMetadata) => {
    return clusteringJobs.find(job => {
        const timedOut = job.timeoutTimestamp < Date.now();
        if (timedOut) {
            buildAndPublishClusteringTimeoutMetric(shadowReportMetadata, job.clusteringResultPreSignedURL);
            return true;
        }
        return false;
    });
}

const parseClusteringResult = (clusteringResult: string) => {
    const config = {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        delimiter: ","
    }
    return Papa.parse(clusteringResult, config);
}

const populateColumnsWithClusteringJobResult = async (shadowReportMetadata: IShadowReportMetadata, succeededJob: ClusteringJob) => {
    return await Excel.run(async (context) => {
        const parsedClusteringResult = parseClusteringResult(succeededJob.clusteringResult);

        const sheet = await loadSheet(context);
        const usedRange = await loadSheetUsedRange(context, sheet);
        const range = sheet.getRange(`${buildColumnAddressFromColumnIndex(succeededJob.clusterColumnIndex)}${2}:${buildColumnAddressFromColumnIndex(succeededJob.topicColumnIndex)}${usedRange.rowCount}`);
        const populatedColumns = parsedClusteringResult.data.map((row) => {
            const suggestedCluster = row['SuggestedCluster'];
            const clusterTopics = row['ClusterTopics'];
            return [suggestedCluster, clusterTopics];
        });
        range.values = populatedColumns;
        await context.sync();

        buildAndPublishClusteringResultMetric(shadowReportMetadata, countUniqueNumberOfSuggestedClusters(parsedClusteringResult))
    })
}

const countUniqueNumberOfSuggestedClusters = (clusteringResult) => {
    const set = new Set();
    clusteringResult.data.forEach((row) => set.add(row['SuggestedCluster']));
    return set.size
}

const buildAndPublishClusteringTimeoutMetric = async(shadowReportMetadata: IShadowReportMetadata, clusteringResultFilePreSignedURL: string) => {
    const metric = await buildMetric("ClusteringTimeout", shadowReportMetadata);

    metric.addCount("ClusteringTimeout", 1);
    metric.addProperty("Url", clusteringResultFilePreSignedURL);
    validateAndPublishMetric(metric);
}

const buildAndPublishClusteringTriggeredMetric = async(
    shadowReportMetadata: IShadowReportMetadata,
    shadowReportName: string,
    shadowReportType: string,
    clusteringTypes: Array<ClusteringSourceType>
) => {
    const metric = await buildMetric("Clustering", shadowReportMetadata);

    metric.addProperty("ShadowReportName", shadowReportName);
    metric.addProperty("ShadowReportType", shadowReportType);
    metric.addProperty("ClusteringTypes", clusteringTypes.join(","));
    metric.addCount("ClusteringInvoked", 1);

    Excel.run(async context => {
        const sheet = loadSheet(context);
        const usedRange = await loadSheetUsedRange(context, sheet);

        metric.addProperty("rows", usedRange.rowCount)
    })

    validateAndPublishMetric(metric);
}

const buildAndPublishClusteringResultMetric = async(
    shadowReportMetadata: IShadowReportMetadata,
    numberOfSuggestedClusters: number
) => {
    const metric = await buildMetric("ClusteringResult", shadowReportMetadata);

    metric.addProperty("NumClusters", numberOfSuggestedClusters);
    validateAndPublishMetric(metric);
}

const createEmptyClusteringResultsColumns = async (clusteringTypes: Array<ClusteringSourceType>) => {
    await insertEmptyColumnsForClusteringResults(clusteringTypes);
    await populateHeadersForClusteringResultColumns(clusteringTypes);
}

const useClusteringButton = (props: ClusteringButtonProps) => {
    const {shadowReportMetadata} = useContext(ShadowReportMetadataContext);
    const [clusteringJobsState, setClusteringJobsState] = useState<ClusteringJobsState>(ClusteringJobsState.NEVER_TRIGGERED);
    const [pendingClusteringJobs, setPendingClusteringJobs] = useState<ClusteringJob[]>([]);
    const [completedClusteringJobs, setCompletedClusteringJobs] = useState<ClusteringJob[]>([]);
    const [processedClusteringTypes, setProcessedClusteringTypes] = useState<ClusteringSourceType[]>([])

    const queryClusteringJobsStatusRef = useRef(setTimeout(() => {
    }));
    const [timestamp, setTimestamp] = useState<number>(Date.now());

    const clusteringSourceTypeOrder = Object.values(ClusteringSourceType);
    const clusteringTypes = Array.from(props.selectedClusteringTypes)
        .sort((a, b) => clusteringSourceTypeOrder.indexOf(a) - clusteringSourceTypeOrder.indexOf(b));


    const cleanPendingClusteringJobs = () => {
        clearTimeout(queryClusteringJobsStatusRef.current);
        setPendingClusteringJobs([]);
    }

    const handleClusteringJobFailure = (e) => {
        console.log(e);
        cleanPendingClusteringJobs();
        setClusteringJobsState(ClusteringJobsState.FAILED);
    }

    const enterUploadingState = async () => {
        setClusteringJobsState(ClusteringJobsState.UPLOADING);
        const clusteringResultFilenames = await uploadColumnsForClustering(clusteringTypes, props.shadowReportType);

        return clusteringResultFilenames
    }

    const enterPopulateExcelState = async(succeededJobs: Array<ClusteringJob>) => {
        setClusteringJobsState(ClusteringJobsState.POPULATING_EXCEL);

        succeededJobs.forEach(succeededJob => {
            populateColumnsWithClusteringJobResult(shadowReportMetadata, succeededJob);
        })
    }

    const enterClusteringState = async (clusteringResultFilenames: string[]) => {
        setClusteringJobsState(ClusteringJobsState.CLUSTERING);
        const clusteringResultFilePreSignedURLs = await generateClusteringResultFilePreSignedURLs(clusteringResultFilenames);
        setPendingClusteringJobs(generateClusteringJobsToQuery(clusteringTypes, clusteringResultFilePreSignedURLs));
    }

    const handleRunClustering = async () => {
        try {
            await createEmptyClusteringResultsColumns(clusteringTypes);
            await refreshProcessedClusteringType()
            const clusteringResultFilenames = await enterUploadingState();

            await enterClusteringState(clusteringResultFilenames);
        } catch (e) {
            handleClusteringJobFailure(e);
        } finally {
            buildAndPublishClusteringTriggeredMetric(shadowReportMetadata, props.shadowReportName, props.shadowReportType, props.selectedClusteringTypes);
        }
    }

    const handleClusteringJobsTimeout = async () => {
        setClusteringJobsState(ClusteringJobsState.TIMED_OUT);
        cleanPendingClusteringJobs();
    }

    const handleClusteringJobsSuccess = (succeededJobs: Array<ClusteringJob>) => {
        try {
            enterPopulateExcelState(succeededJobs);
            setClusteringJobsState(ClusteringJobsState.SUCCEEDED)
        } catch (e) {
            handleClusteringJobFailure(e);
        }
    }

    const refreshProcessedClusteringType = async (): Promise<void> => {
        const headers = await Excel.run(async context => {
            return await loadHeader(context)
        });

        setProcessedClusteringTypes(CLUSTER_SOURCE_TYPES.filter(sourceType => {
            return headers.includes(`${sourceType} Topic`);
        }))
    }

    useEffect(() => {
        refreshProcessedClusteringType();
    }, []);

    useEffect(() => {
        if (pendingClusteringJobs.length > 0) {
            queryClusteringJobsStatusRef.current = setTimeout(async () => {
                if (doesAnyClusteringJobTimeout(pendingClusteringJobs, shadowReportMetadata)) {
                    handleClusteringJobsTimeout();
                } else {
                    await Promise.all(pendingClusteringJobs.map(job => queryClusteringResult(job)))
                        .then(() => {
                            if (haveAllClusteringJobFinsihedSuccessfully(pendingClusteringJobs)) {
                                setCompletedClusteringJobs(pendingClusteringJobs);
                                cleanPendingClusteringJobs();
                            }
                            // retry as clustering jobs are still running
                        }).catch((e) => {
                            handleClusteringJobFailure(e);
                        }).finally(() => {
                            setTimestamp(Date.now())
                        })
                }
            }, CLUSTERING_QUERY_INTERVAL_MS);
        }

        return () => {
            clearTimeout(queryClusteringJobsStatusRef.current);
        }
    }, [timestamp, pendingClusteringJobs])

    useEffect(() => {
        if (completedClusteringJobs.length > 0) {
            handleClusteringJobsSuccess(completedClusteringJobs);
        }
    }, [completedClusteringJobs])

    return { handleRunClustering, clusteringJobsState, processedClusteringTypes}
}

export default useClusteringButton;
