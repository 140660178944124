import {useEffect, useState} from "react";

export const MISMATCHING_FIELDS_SHEET_COL_NAME = "mismatchingFields";
export const DIFF_SUMMARY_COL_NAME = "DiffSummary";
export const DIFF_SUMMARY_COL_NAME_WITH_SPACE = "Diff Summary";
export const TAR_IDENTIFIER_COL_NAME = "TAR Identifier";
export const RULE_DIFF_SUMMARY_COL_NAME = "Rule Diff Summary"
export const RULE_DIFF_DETAIL_COL_NAME = "Rule Diff Detail"

export enum ShadowReportType {
    TAXMAN = "TAXMAN",
    TRE = "TRE",
    TRE_V2 = "TRE (V2)",
    UNKNOWN = "Unknown",
}

export enum ClusteringSourceType {
    DIFF_SUMMARY = "Diff Summary",
    RULE_DIFF_SUMMARY = "Rule Diff Summary",
    RULE_DIFF_DETAIL = "Rule Diff Detail",
}

export const CLUSTER_SOURCE_TYPES: ClusteringSourceType[] = Object.values(ClusteringSourceType);

interface ShadowReportInfo {
    shadowReportType: ShadowReportType;
    availableClusteringSourceTypes: Set<ClusteringSourceType>;
}

export const useShadowReportInfo = (): ShadowReportInfo => {
    const [shadowReportType, setShadowReportType] = useState<ShadowReportType>(ShadowReportType.UNKNOWN);
    const [availableClusteringSourceTypes, setAvailableClusteringSourceTypes] = useState(new Set<ClusteringSourceType>([ClusteringSourceType.DIFF_SUMMARY]));

    useEffect(() => {
        const determineShadowReportType = async () => {
            await Excel.run(async (context) => {
                let sheet = context.workbook.worksheets.getFirst()
                await context.sync()

                let usedRange = sheet.getUsedRange();
                usedRange.load("columnCount");
                await context.sync();

                const headerRow = sheet.getRangeByIndexes(0, 0, 1, usedRange.columnCount);
                headerRow.load("values");
                await context.sync()

                const headerValues = headerRow.values;
                if (headerValues[0].indexOf(MISMATCHING_FIELDS_SHEET_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TAXMAN);
                } else if (headerValues[0].indexOf(DIFF_SUMMARY_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TRE);
                } else if (headerValues[0].indexOf(TAR_IDENTIFIER_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TRE_V2);
                } else {
                    setShadowReportType(ShadowReportType.UNKNOWN);
                }

                let clusteringSourceTypes = new Set<ClusteringSourceType>([ClusteringSourceType.DIFF_SUMMARY])
                if (headerValues[0].indexOf(RULE_DIFF_SUMMARY_COL_NAME) !== -1) {
                    clusteringSourceTypes.add(ClusteringSourceType.RULE_DIFF_SUMMARY);
                }
                if (headerValues[0].indexOf(RULE_DIFF_DETAIL_COL_NAME) !== -1) {
                    clusteringSourceTypes.add(ClusteringSourceType.RULE_DIFF_DETAIL);
                }
                setAvailableClusteringSourceTypes(clusteringSourceTypes)
            })
        };
        determineShadowReportType()
            .catch(error => {
                const errorMessage = error instanceof Error ? error.message : String(error);
                console.log("Shadow Report Type could not be determined: " + errorMessage);
            });
    }, []);

    return {
        shadowReportType: shadowReportType,
        availableClusteringSourceTypes: availableClusteringSourceTypes
    };
}
