import * as React from "react";
import {useContext, useState} from "react";
import {Button, Container, SpaceBetween, Spinner} from "@amzn/awsui-components-react/polaris";
import {queryDtpBySamplePointer} from "./RuleFileUtils";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";
import {buildMetric} from "../../../metric/metric";
import {Header, StatusIndicator, StatusIndicatorProps} from "@amzn/awsui-components-react";
import {ShadowReportMetadataContext} from "../../../contexts/ShadowReportMetadataContext";
import {ShadowReportMetadataEntries} from "../../../storage/ShadowReportMetadataSheet";

enum RuleFileDiffStatus {
    NONE,
    SUCCEEDED,
    FAILED,
    GETTING_TRANSACTION_IDENTIFIER,
    GETTING_DECISIONS_AND_RULE_FILE_DIFFS,
}

export interface DtpSampleKeyRequest {
    auditTrailSamplePointerGamma: string;
    auditTrailSamplePointerProd: string;
    realm: string;
    services: string;
}

const START_INSERT_RANGE = "A";
const END_INSERT_RANGE = "F";
const CLONED_SHEET_NAME = "RuleFileAnalysis";
const MATCHED_DIFF_COL_NAME = "Exact Match Samples Diff";
const MATCHED_DIFF_COUNT_COL_NAME = "Exact match samples pair count";
const GAMMA_DECISION_COL_NAME = "Gamma samples";
const GAMMA_DECISION_COUNT_COL_NAME = "Gamma samples count";
const PROD_DECISION_COL_NAME = "Prod samples";
const PROD_DECISION_COL_COUNT_NAME = "Prod samples count";

export function RuleFileDiffButtonSamplePointer({shadowReportName, shadowReportType}) {
    const {shadowReportMetadata} = useContext(ShadowReportMetadataContext);
    const [status, setStatus] = useState(RuleFileDiffStatus.NONE);
    const [errorMessage, setErrorMessage] = useState("");
    const [dtpRequest, setDtpRequest] = useState(undefined);
    const [changedRuleFiles, setChangedRuleFiles] = useState(undefined);
    const [gammaRuleDecisions, setGammaRuleDecisions] = useState(undefined);
    const [prodRuleDecisions, setProdRuleDecisions] = useState(undefined);
    const [uniqueRuleFiles, setUniqueRuleFiles] = useState(undefined);

    const isTerminalStatus = (status: RuleFileDiffStatus): boolean => {
        return [RuleFileDiffStatus.NONE, RuleFileDiffStatus.SUCCEEDED, RuleFileDiffStatus.FAILED].includes(status);
    };

    const cloneSheet = async () => {
        await Excel.run(async (context) => {
            let clusteringSheet = context.workbook.worksheets.getItemOrNullObject(CLONED_SHEET_NAME)

            await context.sync()
            if (!clusteringSheet.isNullObject) {
                return
            }

            let originalSheet = context.workbook.worksheets.getFirst();
            await context.sync()

            let newSheet = originalSheet.copy(Excel.WorksheetPositionType.end);
            newSheet.name = CLONED_SHEET_NAME;
            let range = newSheet.getRange(`${START_INSERT_RANGE}:${END_INSERT_RANGE}`);
            range.insert(Excel.InsertShiftDirection.right);
            newSheet.getCell(0, 0).values = [[MATCHED_DIFF_COL_NAME]];
            newSheet.getCell(0, 1).values = [[MATCHED_DIFF_COUNT_COL_NAME]];
            newSheet.getCell(0, 2).values = [[GAMMA_DECISION_COL_NAME]];
            newSheet.getCell(0, 3).values = [[GAMMA_DECISION_COUNT_COL_NAME]];
            newSheet.getCell(0, 4).values = [[PROD_DECISION_COL_NAME]];
            newSheet.getCell(0, 5).values = [[PROD_DECISION_COL_COUNT_NAME]];
            newSheet.activate();
            await context.sync();
        })
    }


    const persistRuleFileAnalysis = async (rowIndex: number) => {
        await Excel.run(async (context) => {
            let clusteringSheet = context.workbook.worksheets.getItemOrNullObject(CLONED_SHEET_NAME);
            await context.sync()

            if (clusteringSheet.isNullObject) {
                throw new Error(`Cannot get sheet ${CLONED_SHEET_NAME}`)
            }

            await context.sync();
            const range = clusteringSheet.getRange(`${START_INSERT_RANGE}${rowIndex + 1}:${END_INSERT_RANGE}${rowIndex + 1}`);
            range.values = [[
                JSON.stringify(changedRuleFiles), changedRuleFiles.length,
                JSON.stringify(gammaRuleDecisions), gammaRuleDecisions.length,
                JSON.stringify(prodRuleDecisions), prodRuleDecisions.length
            ]]
            await context.sync();
        });
    }


    const buildDtpAuditTrailSamplePointerRequest = async () => {
        setStatus(RuleFileDiffStatus.GETTING_TRANSACTION_IDENTIFIER);
        let dtpRequest: DtpSampleKeyRequest;
        let rowIndex: number;

        await Excel.run(async (context) => {
            const selectedRange = context.workbook.getSelectedRange();
            const sheet = selectedRange.worksheet;
            selectedRange.load("rowIndex");
            await context.sync();
            if (selectedRange.rowIndex == 0) {
                setErrorMessage("Please select a row.");
                console.log("Please select a row.");
                setStatus(RuleFileDiffStatus.FAILED);
                return;
            }

            console.log("Finding request inputs depending on Shadow Report Type...");
            const usedRange = sheet.getUsedRange();
            usedRange.load("columnCount");
            await context.sync();
            console.log(`usedRange.columnCount: ${usedRange.columnCount}`);
            const headerRow = sheet.getRangeByIndexes(0, 0, 1, Math.min(100, usedRange.columnCount));
            headerRow.load("values");
            await context.sync();
            const headerValues = headerRow.values[0];
            console.log(`headerValues: ${headerValues}`);

            let auditTrailPointerCellGamma;
            let auditTrailPointerCellProd;
            let dtpQueryRealm: string = shadowReportMetadata[ShadowReportMetadataEntries.ShadowReportRegion];
            let dtpQueryServices: string, auditTrailPointerGamma: string, auditTrailPointerProd: string;

            console.log("Getting request input for TRE Shadow Report");
            // TRE Shadow Report queries TACOS in DTP
            dtpQueryServices = "TRE,TACOS";
            // Get sample keys
            auditTrailPointerCellGamma = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("Candidate AuditTrail Key"));
            auditTrailPointerCellProd = sheet.getCell(selectedRange.rowIndex, headerValues.indexOf("Authority AuditTrail Key"));
            auditTrailPointerCellGamma.load("values");
            auditTrailPointerCellProd.load("values");
            await context.sync();
            auditTrailPointerGamma = String(auditTrailPointerCellGamma.values.flat()[0]);
            auditTrailPointerProd = String(auditTrailPointerCellProd.values.flat()[0]);

            rowIndex = selectedRange.rowIndex;
            dtpRequest = {
                auditTrailSamplePointerGamma: auditTrailPointerGamma,
                auditTrailSamplePointerProd: auditTrailPointerProd,
                realm: dtpQueryRealm,
                services: dtpQueryServices,
            };
        });
        return {dtpRequest, rowIndex};
    };

    const queryDecisionsAndRuleFileDiffs = async (dtpRequest: DtpSampleKeyRequest) => {
        setStatus(RuleFileDiffStatus.GETTING_DECISIONS_AND_RULE_FILE_DIFFS);
        return queryDtpBySamplePointer(dtpRequest);
    };

    const processResponse = async (dtpResponse) => {
        setChangedRuleFiles(dtpResponse.ruleChangeResponses);
        setGammaRuleDecisions(dtpResponse.gammaDecisions);
        setProdRuleDecisions(dtpResponse.prodDecisions);
        const usedRuleFiles = dtpResponse.gammaDecisions.flatMap((ruleDecision) =>
            ruleDecision.ruleIds.map((ruleId) => {
                return {
                    ruleFileNamePrefix: ruleId.ruleFileNamePrefix,
                    ruleFileVersion: ruleId.ruleFileVersion,
                };
            })
        );
        const uniqueUsedRuleFiles = getUniqueRuleFiles(usedRuleFiles);
        setUniqueRuleFiles(uniqueUsedRuleFiles);
    };

    function getUniqueRuleFiles(ruleFiles) {
        return ruleFiles.filter(
            (value, index, array) =>
                index ==
                array.findIndex(
                    (item) =>
                        item.ruleFileNamePrefix == value.ruleFileNamePrefix &&
                        item.ruleFileVersion.codeVersion == value.ruleFileVersion.codeVersion &&
                        item.ruleFileVersion.majorVersion == value.ruleFileVersion.majorVersion &&
                        item.ruleFileVersion.minorVersion == value.ruleFileVersion.minorVersion
                )
        );
    }

    const renderRuleFileVersion = (ruleFileVersion) => {
        return `${ruleFileVersion.codeVersion}.${ruleFileVersion.majorVersion}.${ruleFileVersion.minorVersion}`;
    };


    const renderRuleFileDiffLink = (gammaRuleFile, prodRuleFile) => {
        return `https://console.tax.amazon.dev/tacoma/diff/${gammaRuleFile.ruleFileType}/${gammaRuleFile.ruleFileLabel}/${gammaRuleFile.ruleFileVersion.codeVersion}/${gammaRuleFile.ruleFileVersion.majorVersion}/${prodRuleFile.ruleFileVersion.codeVersion}/${prodRuleFile.ruleFileVersion.majorVersion}/?mode=testing`;
    };

    const clickDTPRuleDiffTwoPartKey = async () => {
        const metric = await buildMetric("RuleFileAuditTrailDiff", shadowReportMetadata);

        metric.addCount("RuleFileAuditTrailDiffInvoked", 1);
        metric.addProperty("ShadowReportName", shadowReportName);
        metric.addProperty("ShadowReportType", shadowReportType);

        try {

            cloneSheet();
            setDtpRequest(undefined);
            setErrorMessage(undefined);
            setGammaRuleDecisions(undefined);
            setProdRuleDecisions(undefined);
            setChangedRuleFiles(undefined);
            setUniqueRuleFiles(undefined);
            let {dtpRequest: tempDtpRequest, rowIndex} = await buildDtpAuditTrailSamplePointerRequest();
            if (tempDtpRequest !== undefined) {
                setDtpRequest(tempDtpRequest);
                metric.addProperty("Realm", tempDtpRequest.realm);
                const dtpResponse = await queryDecisionsAndRuleFileDiffs(tempDtpRequest);
                console.log(`DTP Response: ${JSON.stringify(dtpResponse)}`);
                await processResponse(dtpResponse);
                await persistRuleFileAnalysis(rowIndex);
                setStatus(RuleFileDiffStatus.SUCCEEDED);
            }
        } catch (err) {
            setErrorMessage(`Error: ${err}`);
            console.error(`Error: ${err}`);
            setStatus(RuleFileDiffStatus.FAILED);
        } finally {
            validateAndPublishMetric(metric);
        }
    };

    let description = "";
    let uiStatusIcon: StatusIndicatorProps.Type = "in-progress";
    switch (status) {
        case RuleFileDiffStatus.NONE:
            break;
        case RuleFileDiffStatus.SUCCEEDED:
            uiStatusIcon = "success";
            description = "Query succeeded.";
            break;
        case RuleFileDiffStatus.FAILED:
            uiStatusIcon = "error";
            description = "Query failed.";
            break;
        case RuleFileDiffStatus.GETTING_TRANSACTION_IDENTIFIER:
            description = "Getting transaction identifier...";
            break;
        case RuleFileDiffStatus.GETTING_DECISIONS_AND_RULE_FILE_DIFFS:
            description = "Getting rule file diffs...";
            break;
    }

    return (
        <Container
            header={
                <Header description="Select a row and click the button to get Rule File Diff information.">
                    AuditTrail Sample Pointer Rule File Diff Analysis
                </Header>
            }
        >
            <SpaceBetween direction="vertical" size={"s"}>
                <Button variant="primary" onClick={clickDTPRuleDiffTwoPartKey}>
                    {isTerminalStatus(status) ? "Rule File Diffs" : <Spinner/>}
                </Button>
                {status != RuleFileDiffStatus.NONE && <StatusIndicator type={uiStatusIcon}>{description}</StatusIndicator>}
                {errorMessage && <p className="awsui-util-status-negative"> {errorMessage} </p>}
                {dtpRequest && (
                    <SpaceBetween direction="vertical" size={"s"}>
                        <strong>Query Input:</strong>
                        <p>
                            AuditTrail SamplePointer (GAMMA): <strong>{dtpRequest["auditTrailSamplePointerGamma"]}</strong>
                            AuditTrail SamplePointer (PROD): <strong>{dtpRequest["auditTrailSamplePointerProd"]}</strong>
                            <br/>
                            Realm: <strong>{dtpRequest["realm"]}</strong>
                            <br/>
                            Services: <strong>{dtpRequest["services"]}</strong>
                            <br/>
                        </p>
                    </SpaceBetween>
                )}
                {gammaRuleDecisions && prodRuleDecisions && uniqueRuleFiles && changedRuleFiles && gammaRuleDecisions && prodRuleDecisions && (
                    <SpaceBetween direction="vertical" size={"s"}>
                        <strong>Rule File Decisions:</strong>
                        <p>
                            Number of prod rule decisions: <strong>{prodRuleDecisions.length}</strong>
                            <br/>
                            Number of gamma rule decisions: <strong>{gammaRuleDecisions.length}</strong>
                            <br/>
                            Number of unique rule files hit: <strong>{uniqueRuleFiles.length}</strong>
                            <br/>
                            Number of matched rule file decisions with changed output: <strong>{changedRuleFiles.length}</strong>
                            <br/>
                            <ol>
                                {uniqueRuleFiles.map((ruleFile) => (
                                    <li key={ruleFile.ruleFileNamePrefix}>
                                        {ruleFile.ruleFileNamePrefix} [{renderRuleFileVersion(ruleFile.ruleFileVersion)}]
                                    </li>
                                ))}
                            </ol>
                        </p>
                    </SpaceBetween>
                )}
                {changedRuleFiles && (
                    <SpaceBetween direction="vertical" size={"s"}>
                        {changedRuleFiles?.length > 0 ? (
                            <>
                                <strong>Changed Rule Files:</strong>
                                <ol>
                                    {changedRuleFiles.map((ruleFile) => (
                                        <li key={ruleFile.gammaRuleFile.ruleFileNamePrefix}>
                                            <a href={renderRuleFileDiffLink(ruleFile.gammaRuleFile, ruleFile.prodRuleFile)}>
                                                {ruleFile.gammaRuleFile.ruleFileNamePrefix}
                                            </a>{" "}
                                            changed from [{renderRuleFileVersion(ruleFile.prodRuleFile.ruleFileVersion)}] to [
                                            {renderRuleFileVersion(ruleFile.gammaRuleFile.ruleFileVersion)}]
                                        </li>
                                    ))}
                                </ol>
                            </>
                        ) : (
                            <strong>No rule file diff is detected.</strong>
                        )}
                    </SpaceBetween>
                )}
            </SpaceBetween>
        </Container>
    );
}
