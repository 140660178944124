import { midwayFetch } from "../../../auth/fetcher";
import { DtpRequest } from "./RuleFileDiffButton";
import { DtpSampleKeyRequest } from "./RuleFileDiffButtonSamplePointer";

const DTP_QUERY_BY_TWO_PART_KEY_ENDPOINT = "https://dtp-two-part-key-query.shadow-report-addin.tax.amazon.dev";

export const queryDtpBySamplePointer = async (dtpRequest: DtpSampleKeyRequest) => {
  console.log(`Querying decisions from DTP for ${JSON.stringify(dtpRequest)}`);

  return await midwayFetch(`${DTP_QUERY_BY_TWO_PART_KEY_ENDPOINT}/dtp-sample-key-query`, {
    method: "POST",
    body: JSON.stringify(dtpRequest),
  }).then((resp) => resp.json());
};

export const queryDtpByTwoPartKey = async (dtpRequest: DtpRequest) => {
  console.log(`Querying decisions from DTP for ${JSON.stringify(dtpRequest)}`);

  return await midwayFetch(`${DTP_QUERY_BY_TWO_PART_KEY_ENDPOINT}/dtp-two-part-key-query`, {
    method: "POST",
    body: JSON.stringify(dtpRequest),
  }).then((resp) => resp.json());
};
